import {
    ThemeColorVariable,
    ThemeIconSizeVariable,
    ThemeSpaceVariable,
} from "@caspeco/casper-ui-library.base-ui.theme";
import { Icon, Icons } from "@caspeco/casper-ui-library.components.icon";
import { NavigationListButton } from "@caspeco/casper-ui-library.components.navigation-list";
import { Text } from "@caspeco/casper-ui-library.components.text";
import { Flex } from "@chakra-ui/react";
interface ISideNavigationMenu {
    onClick: () => void;
    areaLabel?: string;
    icon: Icons;
    text: string;
}

export function SideNavigationMenuButton({ onClick, areaLabel, icon, text }: ISideNavigationMenu) {
    return (
        <NavigationListButton onClick={onClick} aria-label={areaLabel}>
            <Flex gap={ThemeSpaceVariable.Small} align="center">
                <Icon
                    icon={icon}
                    size={ThemeIconSizeVariable.Small}
                    color={ThemeColorVariable.OnBackground}
                />
                <Text>{text}</Text>
            </Flex>
        </NavigationListButton>
    );
}
