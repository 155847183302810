import { useParams, useSearchParams } from "react-router-dom";
import { getCurrency } from "translations/currency";

export * from "api/api-hooks";

export * from "./available-days";
export * from "./use-date-display";
export * from "./use-selected-rule";
export * from "./use-web-conditions";
export * from "./use-time-text";
export * from "./use-mobile-view";

export function useCurrencyCode() {
    const [searchParams] = useSearchParams();
    const system = searchParams.get("system") ?? "";
    const currency = getCurrency(system);
    return currency;
}

export function useBookingIdParam() {
    const { bookingId } = useParams();
    return bookingId ?? "";
}
