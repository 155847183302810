import { Article, WebBooking } from "types";
export * from "./datepicker-utils";
export * from "./event-utils";
export * from "./moment-utils";
export * from "./payment-utils";

export function isGuid(string: string) {
    return !!string.match(
        /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i
    );
}

/**
 * Filters and returns unique table articles from a webbooking by combining article group ID and time range.
 *
 * @param {WebBooking} webBooking - The booking data containing articles.
 * @returns {Article[]} Unique table articles based on group ID and time range.
 */
export const getUniqueContainerArticlesByTimeAndGroup = (webBooking: WebBooking) => {
    if (!webBooking?.articles) return [];
    const uniqueResources = webBooking.articles
        .filter((article) => article.isTable)
        .reduce<{ [key: string]: Article }>((acc, article) => {
            const timeKey = `${article.start.format("HH:mm")}-${article.end.format("HH:mm")}`;
            const groupKey = `${article.articleGroupId}-${timeKey}`;

            if (!acc[groupKey]) {
                acc[groupKey] = article;
            }

            return acc;
        }, {});
    return Object.values(uniqueResources);
};
