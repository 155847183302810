interface GeneralMessage {
    message: string;
    messageData: Record<string, any>;
}

export class ValidationResult {
    generalErrors: GeneralMessage[];

    constructor(values?: Partial<ValidationResult>) {
        this.generalErrors = values?.generalErrors ?? [];
    }

    isValid(): boolean {
        return this.generalErrors.length === 0;
    }

    getGeneralError(): PaymentErrors {
        const error = this.generalErrors.at(0);
        const paymentErrorMap = getPaymentError();
        const errorType = paymentErrorMap[error.message];
        return errorType;
    }

    isDoubleBookingException(): boolean {
        return this.getGeneralError() === PaymentErrors.DoubleBookingException;
    }
}

export enum PaymentErrors {
    AuthFailed = "AuthFailed",
    CreditFailed = "CreditFailed",
    DoubleBookingException = "DoubleBookingException",
    Expired = "Expired",
    PaymentCanceled = "PaymentCanceled",
    ProcessFailed = "ProcessFailed",
    RegistrationFailed = "RegistrationFailed",
}

const getPaymentError = (): Record<string, PaymentErrors> => {
    return {
        "webBooking.paymentErrors.AuthFailed": PaymentErrors.AuthFailed,
        "webBooking.paymentErrors.CreditFailed": PaymentErrors.CreditFailed,
        "webBooking.paymentErrors.DoubleBookingException": PaymentErrors.DoubleBookingException,
        "webBooking.paymentErrors.Expired": PaymentErrors.Expired,
        "webBooking.paymentErrors.PaymentCanceled": PaymentErrors.PaymentCanceled,
        "webBooking.paymentErrors.ProcessFailed": PaymentErrors.ProcessFailed,
        "webBooking.paymentErrors.RegistrationFailed": PaymentErrors.RegistrationFailed,
    };
};
