import { AvailableTimesUnit, TimeSlot, WebRule } from "types";

export function mergeTimeSetsByTitle(timeSets: WebRule[]): WebRule[] {
    const timeSetMap: { [key: string]: WebRule } = {};

    timeSets.forEach((timeSet) => {
        const key = timeSet.title;
        const existingTimeSet = timeSetMap[key];
        if (existingTimeSet) {
            const groupedTimes: { [key: string]: TimeSlot } = {};

            [...existingTimeSet.times, ...timeSet.times].forEach((time) => {
                const timeKey = time.start;
                groupedTimes[timeKey.format("YYYY-MM-DD HH:mm:ss")] = time;
            });
            timeSetMap[key].times = Object.values(groupedTimes);
        } else {
            // Add the current timeSet directly to the map if it's not already there
            timeSetMap[key] = timeSet;
        }
    });

    return Object.values(timeSetMap);
}

export const getRuleById = (ruleId: number, unit: AvailableTimesUnit[]): WebRule => {
    if (!unit) return null;
    return unit
        .flatMap((u) => u.sections)
        .flatMap((s) => s.timeSets)
        .find((t) => t.id === ruleId);
};
