import { Button } from "@caspeco/casper-ui-library.components.button";
import { useWebBooking } from "hooks";
import { useTranslation } from "react-i18next";
import { isWaitList } from "utils/booking";

interface CancelButtonProps {
    onClick: () => void;
    isLoading: boolean;
}

export default function CancelButton({ onClick, isLoading }: CancelButtonProps) {
    const { t } = useTranslation();
    const { data: webBooking } = useWebBooking();
    const isWaitListBooking = isWaitList(webBooking);

    return (
        <Button onClick={onClick} variant="ghost" isLoading={isLoading} alignSelf="flex-end">
            {isWaitListBooking ? t("waitList.waitListBookingCancel") : t("cancelBookingButton")}
        </Button>
    );
}
