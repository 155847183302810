import { useExternalBookingSettings } from "hooks";
import { t } from "i18next";
import { memo } from "react";
import { Control, Controller, ControllerFieldState, ControllerRenderProps } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ContactInfoForm, useBookingActionsContext } from "store";
import { PaymentMethodLabel } from "./payment-label";
import { PaymentMethod } from "types";
import {
    ThemeColorVariable,
    ThemeFontSizeVariable,
    ThemeFontVariable,
    ThemeBorderVariable,
    ThemeRadiusVariable,
    ThemeSpaceVariable,
} from "@caspeco/casper-ui-library.base-ui.theme";
import { RadioAddon } from "@caspeco/casper-ui-library.components.radio";
import { useRadioGroup } from "@caspeco/casper-ui-library.components.radio-group";
import {
    FormControl,
    FormErrorMessage,
    FormLabel,
} from "@caspeco/casper-ui-library.components.formcontrol";
import { Box } from "@caspeco/casper-ui-library.components.box";
import { Stack } from "@caspeco/casper-ui-library.components.stack";
import { Divider } from "@caspeco/casper-ui-library.components.divider";

interface InnerField {
    options: PaymentMethod[];
    field: ControllerRenderProps<ContactInfoForm, "payMethod">;
    fieldState: ControllerFieldState;
}

const InnerField = memo(({ options, field, fieldState }: InnerField) => {
    const { t } = useTranslation();
    const { setPaymentMethod } = useBookingActionsContext();

    const onChange = (nextValue: string) => {
        field.onChange(nextValue);
        setPaymentMethod(parseInt(nextValue));
    };

    const { getRootProps, getRadioProps } = useRadioGroup({
        name: field.name,
        value: field.value?.toString(),
        onChange,
    });
    const { ...radioGroupProps } = getRootProps();
    return (
        <FormControl isInvalid={Boolean(fieldState.error)}>
            <FormLabel
                color={ThemeColorVariable.OnBackgroundHeader}
                fontSize={ThemeFontSizeVariable.Large}
                fontFamily={ThemeFontVariable.SubHeader}
                fontWeight="medium"
            >
                {t("paymentMethods.title")}
            </FormLabel>
            <Box
                border={ThemeBorderVariable.XSmall}
                borderColor={ThemeColorVariable.OnSurfaceBorder}
                borderRadius={ThemeRadiusVariable.Small}
                bg={ThemeColorVariable.Surface}
            >
                <Stack
                    py={ThemeSpaceVariable.Small}
                    spacing={ThemeSpaceVariable.Small}
                    divider={
                        <Divider
                            my={ThemeSpaceVariable.Medium}
                            borderColor={ThemeColorVariable.OnSurfaceBorder}
                        />
                    }
                    {...radioGroupProps}
                >
                    {options.map((option, index) => {
                        const radioProps = getRadioProps({
                            value: JSON.stringify(option),
                        });
                        return (
                            <RadioAddon
                                key={index}
                                id={option.toString()}
                                px={ThemeSpaceVariable.Medium}
                                flexDirection="row-reverse"
                                justifyContent="space-between"
                                {...radioProps}
                            >
                                <PaymentMethodLabel paymentMethod={option} />
                            </RadioAddon>
                        );
                    })}
                </Stack>
            </Box>
            <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
        </FormControl>
    );
});

InnerField.displayName = "InnerField";

export function PaymentMethodField({ control }: { control: Control<ContactInfoForm, any> }) {
    const { data: settings } = useExternalBookingSettings();
    if (!settings) return null;
    const availablePaymentMethods = settings.paymentMethods;

    return (
        <Controller
            name="payMethod"
            control={control}
            rules={{
                required: {
                    value: true,
                    message: t("enterPaymentMethod"),
                },
            }}
            render={({ field, fieldState }) => (
                <InnerField
                    options={availablePaymentMethods}
                    field={field}
                    fieldState={fieldState}
                />
            )}
        />
    );
}
