import {
    Article,
    BookingChange,
    BookingMenu,
    BookingStatus,
    ChargeStatus,
    Contact,
    Customer,
    PaymentEvent,
    WaitListOffer,
    WebRule,
} from "types";
import { parseDate, parseDateWithTimeZone } from "utils";

export class WebBooking {
    guid: string;
    unitId: number;
    start: IMoment;
    end: IMoment;
    status: BookingStatus;
    guests: number;
    guestsChildren: number;
    message: string;
    createdDate: IMoment;
    changeDate: IMoment;
    recoupTime: number;
    articles: Article[];
    nonTableArticles: Article[];
    chargeType: ChargeType;
    chargeId: string;
    chargeShouldPayAmount: number;
    chargeShouldPayVat: number;
    chargeAuthorizedAmount: number;
    chargePayedAmount: number;
    chargeRefundedAmount: number;
    chargeStatus: ChargeStatus;
    chargePaymentLinkExpirationDate: IMoment;
    contactId: number;
    contact: Contact;
    customer: Customer;
    menus: BookingMenu[];
    isInUpdate: boolean;
    globalBookingNumber: string;
    language: string;
    cancelAllowedBeforeUtc: IMoment | null;
    webBookingRules: WebRule[] | null;
    history: BookingChange[] | null;
    waitListOffers: WaitListOffer[] | null;
    paymentEvents: PaymentEvent[];

    constructor(values?: Partial<WebBooking>) {
        this.guid = values?.guid ?? "";
        this.unitId = values?.unitId ?? 0;
        this.start = parseDate(values?.start);
        this.end = parseDate(values?.end);
        this.status = values?.status ?? BookingStatus.Unset;
        this.guests = values?.guests ?? 0;
        this.guestsChildren = values?.guestsChildren ?? 0;
        this.message = values?.message ?? "";
        this.createdDate = parseDate(values?.createdDate);
        this.changeDate = parseDate(values?.changeDate);
        this.recoupTime = values?.recoupTime ?? 0;
        this.articles = values?.articles.map((a) => new Article(a)) ?? [];
        this.nonTableArticles = values?.nonTableArticles.map((a) => new Article(a)) ?? [];
        this.chargeType = values?.chargeType ?? ChargeType.None;
        this.chargeId = values?.chargeId ?? "";
        this.chargeShouldPayAmount = values?.chargeShouldPayAmount ?? 0;
        this.chargeShouldPayVat = values?.chargeShouldPayVat ?? 0;
        this.chargeAuthorizedAmount = values?.chargeAuthorizedAmount ?? 0;
        this.chargePayedAmount = values?.chargePayedAmount ?? 0;
        this.chargeRefundedAmount = values?.chargeRefundedAmount ?? 0;
        this.chargeStatus = values?.chargeStatus ?? ChargeStatus.Unknown;
        this.chargePaymentLinkExpirationDate = parseDate(values?.chargePaymentLinkExpirationDate);
        this.contactId = values?.contactId ?? 0;
        this.contact = values?.contact ?? ({} as Contact);
        this.customer = values?.customer ?? ({} as Customer);
        this.menus = values?.menus ?? [];
        this.isInUpdate = values?.isInUpdate ?? false;
        this.globalBookingNumber = values?.globalBookingNumber ?? "";
        this.language = values?.language ?? "en";
        this.cancelAllowedBeforeUtc = values?.cancelAllowedBeforeUtc
            ? parseDateWithTimeZone(values.cancelAllowedBeforeUtc)
            : null;
        this.webBookingRules = values?.webBookingRules?.map((rule) => new WebRule(rule)) ?? null;
        this.history = values?.history?.map((change) => new BookingChange(change)) ?? null;
        this.waitListOffers =
            values?.waitListOffers?.map((offer) => new WaitListOffer(offer)) ?? null;
        this.paymentEvents = values?.paymentEvents.map((p) => new PaymentEvent(p)) ?? [];
    }
}

export enum ChargeType {
    None = 0,
    PrePayment = 1,
    NoShow = 2,
    ManualChargeOrCapturedNoShowPayment = 4,
}
