import { PaymentMethod, ThemeSettings } from "types";

export interface ExternalBookingSettings {
    minGuests: number;
    maxGuests: number;
    openingTime: number;
    closingTime: number;
    companyName: string;
    genericSignature: string;
    maxGuestsInfo: string;
    defaultAmountGuests?: number;
    phoneNumber: string;
    email: string;
    defaultExpandedSectionIds: number[];
    unitsMetaData: UnitMetaDataExternal;
    messageDescription: string;
    messagePUL: string;
    messageBookingClosed: string;
    webConditions: string;
    menuHeader: string;
    menuMessage: string;
    displayMessageBox: boolean;
    displayMenuMessageBox: boolean;
    menuMessageDescription: string;
    gdprInfo: string;
    lastGrab: boolean;
    newsletter: boolean;
    gaTrackingId: string;
    bookingWindowAsMonth: number;
    bookingWindowUnit: TemporalUnits;
    paymentMethods: PaymentMethod[] | null;
    displayCompanyName: boolean;
    enableInvoicing: boolean;
    deliveryInfoDescription: string;
    themeSettings: ThemeSettings;
    address: string;
    disableCancel: boolean;
    introductionMessage: string;
    introductionMessageHeader: string;
}

export enum TemporalUnits {
    Months,
    Weeks,
    Days,
}

export enum BookingWebSettingsFlag {
    displayCustomerName = 1,
}

export interface UnitMetaDataExternal {
    activateWebChildren: boolean;
    bookingWindow: number;
    confirmEmail: boolean;
    confirmSms: boolean;
    contactSetting: any;
    cssUrl: string;
    id: number;
    maxWebGuests: number;
    messageCompleted: string;
    messageExceededGuests: string;
    unitId: number;
    name: string;
    colorTheme: string;
    showTimeFilter: boolean;
    binaryFlags: BookingWebSettingsFlag;
}
