import { ThemeColorVariable, ThemeSpaceVariable } from "@caspeco/casper-ui-library.base-ui.theme";
import { useTranslation } from "react-i18next";
import { LogoCard, LogoSwish, LogoVipps } from "components/custom-icons";
import { useParams } from "react-router-dom";
import { PaymentMethod } from "types";
import { Box } from "@caspeco/casper-ui-library.components.box";
import { Text } from "@caspeco/casper-ui-library.components.text";

export function PaymentMethodLabel({ paymentMethod }: { paymentMethod: PaymentMethod }) {
    const { t } = useTranslation();
    const iconColor = ThemeColorVariable.OnSurface;

    const { system } = useParams();

    const country = system.split("_")[0];

    const isSESystem = country === "se";
    const isNOSystem = country === "no";

    const getMobileText = () => {
        if (isSESystem) {
            return "Swish";
        }
        if (isNOSystem) {
            return "Vipps";
        }
        return t(`paymentMethods.${paymentMethod}`);
    };

    switch (paymentMethod) {
        case PaymentMethod.Mobile:
            return (
                <Box display="flex" alignItems="center">
                    {isSESystem ? <LogoSwish /> : isNOSystem ? <LogoVipps /> : null}
                    <Text ml={ThemeSpaceVariable.Small}>{getMobileText()}</Text>
                </Box>
            );
        case PaymentMethod.CreditCard:
            return (
                <Box color={iconColor} display="flex" alignItems="center">
                    <LogoCard />
                    <Text ml={ThemeSpaceVariable.Small}>
                        {t(`paymentMethods.${paymentMethod}`)}
                    </Text>
                </Box>
            );
        default:
            return null;
    }
}
