import { useMutation } from "@tanstack/react-query";
import { post } from "api/api-methods";
import i18n from "config/i18n";
import { useError } from "context/error-context";
import { getLocaleFromLang } from "helpers";
import { trackEvent } from "logging/insights";
import queryClient from "query-client";
import { useNavigate, useParams } from "react-router-dom";
import { GUID_STORAGE_KEY, State } from "store";
import { CreateReservationParameters, WebBooking } from "types";
import { sendTrackingEventToParent, TrackingEventType } from "utils";

export const useFinalizeBooking = () => {
    const { system } = useParams();
    const navigate = useNavigate();
    const { openReservationFailedModal } = useError();

    const finalizeBooking = async (state: State) => {
        const guid = localStorage.getItem(GUID_STORAGE_KEY);
        if (!guid) return;
        const data: CreateReservationParameters = {
            ...state,
            lang: getLocaleFromLang(i18n.language),
            logMessage: "Bokning skapad",
            message: state.contact?.message,
            times: state.times.map((time) => {
                return {
                    ruleId: time.ruleId,
                    // This was an error, we were passing Moments instead of strings
                    start: time.start.toISOString(),
                };
            }),
        };

        let eventName = "BookingFinalized";
        let booking: WebBooking;

        const startTime = new Date().getTime();
        try {
            booking = await post<WebBooking, CreateReservationParameters>(
                `WebBooking/WebBookings/${guid}/Finalize`,
                data
            );
            queryClient.setQueryData<WebBooking>(["booking", guid], new WebBooking(booking));
            localStorage.removeItem(GUID_STORAGE_KEY);

            navigate(`/booking/${system}/${guid}`, {
                state: { isFinal: true },
            });

            // TODO: revisit the scroll event implementation. (logic brought from previous widget 3.0)
            document.documentElement.scrollTop = 0;
            document.body.scrollTop = 0;
            window.parent.postMessage("scroll2top", "*");
        } catch (_error) {
            eventName = "BookingFinalizeFailed";
            openReservationFailedModal();
        }

        const endTime = new Date().getTime();
        const loadTime = endTime - startTime;
        trackEvent(eventName, {
            guests: state.guests,
            children: state.guestsChildren,
            date: state.times[0].start.toISOString(),
            isWaitingList: state.times.length > 1,
            ms: loadTime,
        });
        sendTrackingEventToParent(
            state,
            eventName === "BookingFinalized"
                ? TrackingEventType.bookingFinalized
                : TrackingEventType.bookingFinalizedFailed
        );
        return booking;
    };

    return useMutation({
        mutationFn: finalizeBooking,
        scope: {
            id: "webbooking-create",
        },
    });
};
