import { useQuery } from "@tanstack/react-query";
import { get } from "api/api-methods";
import { useWebBooking } from "hooks";
import { useParams } from "react-router-dom";
import { ExternalBookingSettings } from "types/external-booking-settings";

const getUnitId = () => {
    const url = new URL(window.location.href);
    const pathSegments = url.pathname.split("/").filter(Boolean);
    const unitId = pathSegments[1];

    const parsedUnitId = parseInt(unitId);
    if (!isNaN(parsedUnitId)) return unitId.toString();
};

export function useExternalBookingSettings() {
    const { unitId: unitIdUrl } = useParams();
    const webBooking = useWebBooking().data;

    const unitId = webBooking?.unitId ?? unitIdUrl ?? getUnitId();
    return useQuery({
        queryKey: ["externalBookingSettings"],
        queryFn: async () => {
            const response = await get<ExternalBookingSettings>(
                `/booking/externalBookingSettings/${unitId}`
            );
            return response;
        },
        enabled: !!unitId,
        refetchOnMount: false,
    });
}
