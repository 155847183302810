const isIframe = window.self !== window.top;

const elements = {};

if (isIframe) {
    addEventListener("message", (e) => {
        if (typeof e.data === "string" && e.data.match(/^load/)) {
            const url = e.data.split(" ")[1];
            if (elements[url]) return;

            console.warn("loading external script", url);

            const script = document.createElement("script");
            script.src = url;
            document.head.appendChild(script);
            elements[url] = script;
        }
    });

    window.parent.postMessage("loaded", "*");
}
