import { Moment } from "moment-timezone";
import { parseDate } from "utils";

export class Article {
    articleId: number;
    articleGroupId: number;
    articleGroupName: string;
    departmentId: string;
    unitId: number;
    start: Moment;
    end: Moment;
    name: string;
    tableName: string;
    tableNumber: string;
    tableChairs: number;
    message: string;
    quantity: number;
    isTable: boolean;
    outPriceIncVat: number;
    vat: number;
    menuGuid: string;
    // webFlags: ArticleWebFlags;

    constructor(values?: Partial<Article>) {
        this.articleId = values?.articleId ?? 0;
        this.articleGroupId = values?.articleGroupId ?? 0;
        this.articleGroupName = values?.articleGroupName ?? "";
        this.departmentId = values?.departmentId ?? "";
        this.unitId = values?.unitId ?? 0;
        this.start = parseDate(values?.start);
        this.end = parseDate(values?.end);
        this.name = values?.name ?? "";
        this.tableName = values?.tableName ?? "";
        this.tableNumber = values?.tableNumber ?? "";
        this.tableChairs = values?.tableChairs ?? 0;
        this.message = values?.message ?? "";
        this.quantity = values?.quantity ?? 0;
        this.isTable = values?.isTable ?? false;
        this.outPriceIncVat = values?.outPriceIncVat ?? 0;
        this.vat = values?.vat ?? 0;
        this.menuGuid = values?.menuGuid ?? "";
        // this.webFlags = values?.webFlags ?? ({} as ArticleWebFlags);
    }
}

// TODO: Will be used when implementing menu
// export enum ArticleWebFlags {
//     ShowTextPriceQuantity = 0,
//     ShowTextQuantity = 1,
//     ShowText = 2,
//     Hide = 3,
// }
