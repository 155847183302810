import {
    ThemeIconSizeVariable,
    ThemeSpaceVariable,
} from "@caspeco/casper-ui-library.base-ui.theme";
import { Button } from "@caspeco/casper-ui-library.components.button";
import { Flex } from "@caspeco/casper-ui-library.components.flex";
import { Icon, Icons } from "@caspeco/casper-ui-library.components.icon";
import { useBreakpointValue } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

interface ISideNavigationHeader {
    onClose: () => void;
}
export function SideNavigationHeader({ onClose }: ISideNavigationHeader) {
    const { t } = useTranslation();
    const iconSize = useBreakpointValue({
        base: ThemeIconSizeVariable.Medium,
        md: ThemeIconSizeVariable.Large,
    });

    return (
        <Flex
            align="center"
            w="100%"
            px={ThemeSpaceVariable.Large}
            py={ThemeSpaceVariable.Small}
            mb={ThemeSpaceVariable.Medium}
        >
            <Flex py={ThemeSpaceVariable.Medium}>
                <Button
                    aria-label={t("action_close")}
                    key={iconSize}
                    onClick={onClose}
                    type="button"
                    variant="secondary"
                    w={{ base: "40px", md: "56px" }}
                    h={{ base: "40px", md: "56px" }}
                >
                    <Icon icon={Icons.Close} size={iconSize} />
                </Button>
            </Flex>
        </Flex>
    );
}
