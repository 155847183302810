import { useToast } from "@caspeco/casper-ui-library.components.toast";
import StatusFailedIcon from "components/booking-status/status-failed-icon";
import ErrorTemplate from "components/error-components/error-template";
import { useError } from "context";
import { usePaymentTerminal } from "hooks";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { getSearchParams } from "search-params";
import { GUID_STORAGE_KEY, useBookingActionsContext } from "store";
import { PaymentErrors } from "types/validation-result";

const PaymentError = () => {
    const { t } = useTranslation();
    const { validationResult } = useError();
    const { showToast } = useToast();
    const navigate = useNavigate();
    const { system } = useParams();
    const terminal = usePaymentTerminal();
    const { webBookingId, offerStartTime, isPaymentLink, isNoShow } = getSearchParams();
    const { setState } = useBookingActionsContext();

    const hasRun = useRef(null);
    const storedSystemId = localStorage.getItem("lastKnownSystemId");
    const storedUnitId = localStorage.getItem("lastKnownUnitId");
    const validStoredValues = storedSystemId && storedUnitId;

    const handleStartOver = () => {
        if (validStoredValues) {
            setState({});
            navigate(`/${storedSystemId}/${storedUnitId}`);
        }
    };

    const triggerToast = (title: string, message: string) => {
        showToast({
            type: "error",
            title: title,
            description: message,
            duration: null,
        });
    };

    const noShowOrPrepayment = isNoShow ? "noShow" : "payment";
    useEffect(() => {
        localStorage.setItem(GUID_STORAGE_KEY, webBookingId);
        if (validationResult && !validationResult.isValid() && !hasRun.current) {
            hasRun.current = true;
            const isWaitList = offerStartTime;
            const errorType = validationResult.getGeneralError();

            let shouldShowToast = true;
            let toastMessage = "";
            switch (errorType) {
                case PaymentErrors.PaymentCanceled:
                case PaymentErrors.ProcessFailed:
                case PaymentErrors.RegistrationFailed:
                    toastMessage = t(`paymentErrors.${noShowOrPrepayment}.paymentCanceled`);
                    break;
                case PaymentErrors.DoubleBookingException:
                    toastMessage = t("paymentErrors.doubleBookingException");
                    if (isWaitList) {
                        shouldShowToast = false;
                        const urlDbe = `/booking/${system}/${webBookingId}`;
                        navigate(urlDbe, {
                            state: { failedPaymentConfirmWaitList: true },
                        });
                    }
                    break;
                case PaymentErrors.AuthFailed:
                    toastMessage = t("paymentErrors.authFailed");
                    break;
                case PaymentErrors.CreditFailed:
                    toastMessage = t("paymentErrors.creditFailed");
                    break;
                case PaymentErrors.Expired:
                    toastMessage = t("paymentErrors.expired");
                    break;
                default:
                    break;
            }

            if (isPaymentLink || isWaitList) {
                const url = `/booking/${system}/${webBookingId}${isPaymentLink ? "?paymentLink=true" : ""}`;
                navigate(url);
            }
            const toastTitle = t(`paymentErrors.toastTitle.${noShowOrPrepayment}`);
            if (shouldShowToast) triggerToast(toastTitle, toastMessage);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ErrorTemplate
            icon={<StatusFailedIcon />}
            title={t(`paymentErrors.dialogue.${noShowOrPrepayment}.title`)}
            message={t(`paymentErrors.dialogue.${noShowOrPrepayment}.message`)}
            btnCallback={handleStartOver}
            btnText={t("errorInformation.startOver")}
            isBtnLoading={terminal.isPending}
        />
    );
};

export default PaymentError;
