import { ThemeColorVariable, ThemeSpaceVariable } from "@caspeco/casper-ui-library.base-ui.theme";
import { Text } from "@caspeco/casper-ui-library.components.text";
import { SafeHTML } from "components/safe-HTML";

interface TimeSetCommentProps {
    comment: string;
}

const TimeSetComment = ({ comment }: TimeSetCommentProps) => {
    return (
        <Text mt={ThemeSpaceVariable.XSmall} color={ThemeColorVariable.OnBackgroundSubdued}>
            <SafeHTML
                element="span"
                options={{
                    allowedTags: ["a", "br"],
                    allowedSchemes: ["tel", "mailto", "https"],
                }}
                html={comment.replace(/\n/g, "<br>")}
            />
        </Text>
    );
};

export default TimeSetComment;
