import moment from "moment";
import { ExternalBookingSettings, TemporalUnits } from "types";

const getBookingWindowTimeUnit = (unit: TemporalUnits): "months" | "weeks" | "days" => {
    return TemporalUnits[unit]?.toLowerCase() as "months" | "weeks" | "days";
};

export const isDateWithinBookingWindow = (
    date: Date,
    settings: ExternalBookingSettings
): boolean => {
    const addUnit = getBookingWindowTimeUnit(settings?.bookingWindowUnit);
    return (
        moment(date).local().startOf("day") <=
        moment().subtract(1, "days").add(settings?.bookingWindowAsMonth, addUnit)
    );
};

/**
 * Determines if a given date should be disabled based on several criteria.
 *
 * @param date - The date to check.
 * @param externalBookingSettings - Settings related to the unit.
 * @returns {boolean} - True if the date should be disabled, false otherwise.
 */
export const shouldDisableDate = (
    date: Date,
    externalBookingSettings: ExternalBookingSettings
): boolean => {
    return !isDateWithinBookingWindow(date, externalBookingSettings);
};
