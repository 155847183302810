import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import config from "config/app.config";
import i18n from "config/i18n";

const appInsights = new ApplicationInsights({
    config: {
        connectionString:
            "InstrumentationKey=895b6a01-b84d-4163-903f-353f55535b91;IngestionEndpoint=https://northeurope-2.in.applicationinsights.azure.com/;LiveEndpoint=https://northeurope.livediagnostics.monitor.azure.com/",
        enableAutoRouteTracking: true,
        samplingPercentage: 100,
    },
});

const { isDev } = config;
if (!isDev) {
    appInsights.loadAppInsights();
}

type InsightProps = { [name: string]: string | number | boolean };

const getGlobalProperties = (): InsightProps => {
    const storedSystemId = localStorage.getItem("lastKnownSystemId");
    const storedUnitId = localStorage.getItem("lastKnownUnitId");
    const props: InsightProps = {
        "device.viewport": `${
            window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
        }x${
            window.innerHeight ||
            document.documentElement.clientHeight ||
            document.body.clientHeight
        }`,
        iframe: window.self !== window.top,
        lang: i18n.language,
    };

    if (storedSystemId) {
        props["booking.system"] = storedSystemId;
    }
    if (storedUnitId) {
        props["booking.unit"] = storedUnitId;
    }

    return props;
};

export function trackPageView(name: string, pathname: string) {
    if (isDev) return;
    appInsights.trackPageView({ name, uri: pathname, properties: getGlobalProperties() });
}

export function trackEvent(name: string, properties?: InsightProps) {
    if (isDev) return;
    // Merge global properties with the properties passed in
    properties = { ...getGlobalProperties(), ...properties };
    appInsights.trackEvent({ name, properties });
}
