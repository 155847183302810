import { useQuery } from "@tanstack/react-query";
import { get } from "api/api-methods";
import i18n from "config/i18n";
import { useBookingIdParam } from "hooks";
import { WebBooking } from "types";

const webBookingQuery = (id: string, onSuccess?: (data: WebBooking) => void) => ({
    queryKey: ["booking", id],
    queryFn: async () => {
        const response = await get(`webBooking/webBookings/${id}`).then((response: WebBooking) => {
            return new WebBooking(response);
        });

        if (onSuccess) {
            onSuccess(response);
        }
        return response;
    },
});

export function useWebBooking() {
    const bookingId = useBookingIdParam();

    let onSuccess: (data: WebBooking) => void | undefined = undefined;
    const currentState = history.state;
    const isFinal = currentState?.usr?.isFinal;
    if (!isFinal) {
        // TODO: Check it this still works correctlly
        onSuccess = (data: WebBooking) => i18n.changeLanguage(data.language);
    }

    return useQuery({
        ...webBookingQuery(bookingId, onSuccess),
        refetchOnMount: false,
        // The query will not execute unless theres a unitId from the booking and not in the params
        enabled: !!bookingId,
    });
}
