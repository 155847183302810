import { useWebBooking } from "hooks";

import { Alert } from "@caspeco/casper-ui-library.components.alert";
import { useTranslation } from "react-i18next";

const NoShowChargeAlert = () => {
    const { t } = useTranslation();
    const { data: webBooking } = useWebBooking();

    const payedAmount = webBooking.paymentEvents.at(-1)?.payedAmount;

    return (
        <Alert type="info" iconVerticalAlign="flex-start">
            {t("payment.noShowCharged", {
                amount: t("currency.onlyAmount", {
                    value: payedAmount,
                }),
            })}
        </Alert>
    );
};

export default NoShowChargeAlert;
