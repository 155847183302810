if ("__APP_START_TIME__" in window) {
    const now = new Date();
    const loadTime = now.getTime() - window.__APP_START_TIME__.getTime();
    console.log("Start loading main.tsx: ", loadTime);
}

import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import App from "components/app/app";
import { getInitialCrashReportingSetting } from "components/app/app-helper";
import config from "config/app.config";
import { ErrorProvider } from "context/error-context";
import { trackPageView } from "logging/insights";
import queryClient from "query-client";
import rg4js from "raygun4js";
import React from "react";
import ReactDOM from "react-dom/client";
import { getSearchParams } from "search-params";
import "utils/load-script";

const { system } = getSearchParams();

rg4js("apiKey", import.meta.env.RAYGUN_API_KEY);
rg4js("enableCrashReporting", getInitialCrashReportingSetting());
rg4js("withTags", [`system:${system}`, String(config.environment)]);
rg4js("options", {
    excludedHostnames: ["localhost"],
    ignoreAjaxError: true,
    ignore3rdPartyErrors: true,
});

trackPageView("Booking", window.location.pathname);

if ("__APP_START_TIME__" in window) {
    const now = new Date();
    const loadTime = now.getTime() - window.__APP_START_TIME__.getTime();
    console.log("Before React Render: ", loadTime);
}

ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <ErrorProvider>
                <App />
            </ErrorProvider>
            <ReactQueryDevtools initialIsOpen={false} position="right" />
        </QueryClientProvider>
    </React.StrictMode>
);
