import { useDisclosure } from "@caspeco/casper-ui-library.components.drawer";
import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useState } from "react";
import { ValidationResult } from "types";

export enum WebBookingErrorCode {
    Undefined = 0,
    TemporaryUnavailable = 2,
    PaymentError = 19,
}
interface ErrorContextType {
    error: any;
    setError: Dispatch<SetStateAction<unknown>>;
    isLoading: boolean;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    errorCode: WebBookingErrorCode;
    setErrorCode: Dispatch<SetStateAction<WebBookingErrorCode>>;
    hasErrorCode: boolean;
    hasPaymentError: boolean;
    openReservationFailedModal: () => void;
    closeReservationFailedModal: () => void;
    isOpenReservationFailedModal: boolean;
    setValidationResult: Dispatch<SetStateAction<ValidationResult | null>>;
    validationResult: ValidationResult;
}

interface ErrorProviderProps {
    children: ReactNode;
}

const ErrorContext = createContext<ErrorContextType | undefined>(undefined);

export const ErrorProvider = ({ children }: ErrorProviderProps) => {
    const [error, setError] = useState<any>(null);
    const [errorCode, setErrorCode] = useState(WebBookingErrorCode.Undefined);
    const [validationResult, setValidationResult] = useState<ValidationResult | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const {
        onOpen: openReservationFailedModal,
        onClose: closeReservationFailedModal,
        isOpen: isOpenReservationFailedModal,
    } = useDisclosure();

    return (
        <>
            <ErrorContext.Provider
                value={{
                    error,
                    setError,
                    isLoading,
                    setIsLoading,
                    errorCode,
                    setErrorCode,
                    hasErrorCode: errorCode !== WebBookingErrorCode.Undefined,
                    hasPaymentError: errorCode === WebBookingErrorCode.PaymentError,
                    openReservationFailedModal,
                    closeReservationFailedModal,
                    isOpenReservationFailedModal,
                    validationResult,
                    setValidationResult,
                }}
            >
                {children}
            </ErrorContext.Provider>
        </>
    );
};

export const useError = () => {
    const context = useContext(ErrorContext);
    if (!context) {
        throw new Error("useError must be used within an ErrorProvider");
    }
    return context;
};
