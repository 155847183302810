import {
    ThemeFontSizeVariable,
    ThemeSpaceVariable,
} from "@caspeco/casper-ui-library.base-ui.theme";
import { Stack } from "@caspeco/casper-ui-library.components.stack";
import { Text } from "@caspeco/casper-ui-library.components.text";
import { SafeHTML } from "components/safe-HTML";
import { useReservation, useSelectedRule } from "hooks";
import useWebConditions from "hooks/use-web-conditions";
import { useTranslation } from "react-i18next";

export default function BookingConditions() {
    const { t } = useTranslation();
    const { data: reservation } = useReservation();
    const webConditions = useWebConditions();
    const rule = useSelectedRule();

    if (!webConditions.length) return null;

    return (
        <Stack spacing={ThemeSpaceVariable.Small}>
            <Text fontSize={ThemeFontSizeVariable.Large} fontWeight={"medium"}>
                {t("informationAndConditions")}
            </Text>
            <Stack spacing={ThemeSpaceVariable.Medium} mb={ThemeSpaceVariable.Medium}>
                {webConditions?.map((condition: string, i: number) => {
                    const formattedCondition = condition.replace(/\n/g, "<br>");
                    return (
                        <Text key={i}>
                            <SafeHTML
                                key={`condition_${i}`}
                                className="textFromSettings"
                                element="div"
                                options={{
                                    allowedTags: ["a", "br"],
                                    allowedSchemes: ["tel", "mailto", "https"],
                                }}
                                html={formattedCondition}
                            />
                        </Text>
                    );
                })}

                {rule.hasNoShowRule() && reservation?.chargeShouldPayAmount && (
                    <Text>
                        {t("noShowCondition", {
                            value: reservation.chargeShouldPayAmount,
                            amount: "symbol",
                        })}
                    </Text>
                )}
            </Stack>
        </Stack>
    );
}
