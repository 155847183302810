import { ThemeColorVariable, ThemeSpaceVariable } from "@caspeco/casper-ui-library.base-ui.theme";
import { Heading } from "@caspeco/casper-ui-library.components.heading";
import { AvailableTimesSection } from "types";
import { mergeTimeSetsByTitle } from "utils/time-rule-utils";
import TimeSet from "./time-set/time-set";

interface ISectionOfAvailableTimesProps {
    expanded?: boolean;
    webBookingSection: AvailableTimesSection;
}

export default function SectionOfAvailableTimes(props: ISectionOfAvailableTimesProps) {
    const shouldNotRenderSection = !props.webBookingSection.timeSets.find(
        (x) => x.times.length > 0 || x.isTextRow
    );
    if (shouldNotRenderSection) return null;

    const listOfRules = mergeTimeSetsByTitle(props.webBookingSection.timeSets);

    return (
        <>
            <Heading
                as="h5"
                fontWeight="500"
                flex="1"
                textAlign="left"
                mb={ThemeSpaceVariable.Medium}
                color={ThemeColorVariable.OnBackgroundHeader}
            >
                {props.webBookingSection.name}
            </Heading>
            {listOfRules.map((timeSet) => (
                <TimeSet key={`timeSet${timeSet.id}`} timeSet={timeSet} />
            ))}
        </>
    );
}
