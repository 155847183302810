import { useMutation, useQuery } from "@tanstack/react-query";
import { get, post } from "api/api-methods";
import { useError } from "context/error-context";
import queryClient from "query-client";
import { GUID_STORAGE_KEY, State } from "store";
import { CreateReservationParameters, WebBooking } from "types";

export function useReservation() {
    const id = localStorage.getItem(GUID_STORAGE_KEY);
    return useQuery({
        queryKey: ["booking", id],
        queryFn: async () => {
            return get(`webBooking/webBookings/${id}`).then((response: WebBooking) => {
                return new WebBooking(response);
            });
        },
        enabled: !!id,
    });
}

export const useCreateReservation = () => {
    const { setErrorCode, openReservationFailedModal } = useError();

    const createReservation = async (state: State) => {
        const data: CreateReservationParameters = new CreateReservationParameters(state);
        let booking: WebBooking;

        try {
            booking = await post<WebBooking, CreateReservationParameters>(
                `WebBooking/WebBookings`,
                data
            );
            localStorage.setItem(GUID_STORAGE_KEY, booking.guid);
            queryClient.setQueryData<WebBooking>(
                ["booking", booking.guid],
                new WebBooking(booking)
            );
        } catch (error) {
            console.error(error);
            const errorCode = error?.response.data?.code;
            if (typeof errorCode === "number") {
                setErrorCode(errorCode);
                openReservationFailedModal();
            }
        }

        return booking;
    };

    return useMutation({
        mutationFn: createReservation,
        scope: {
            id: "webbooking-create",
        },
    });
};
