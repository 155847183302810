import { ThemeColorVariable, ThemeSpaceVariable } from "@caspeco/casper-ui-library.base-ui.theme";
import { Box } from "@caspeco/casper-ui-library.components.box";
import { Divider } from "@caspeco/casper-ui-library.components.divider";
import { Drawer } from "@caspeco/casper-ui-library.components.drawer";
import { Icons } from "@caspeco/casper-ui-library.components.icon";
import { Link } from "@caspeco/casper-ui-library.components.link";
import { List } from "@caspeco/casper-ui-library.components.list";
import { useDisclosure } from "@caspeco/casper-ui-library.components.modal";
import { PrivacyPolicyModal } from "components/privacy-policy-modal";
import LanguageModal from "components/side-navigation/language-modal";
import { PoweredByCaspeco } from "components/side-navigation/powered-by-caspeco";
import { SideNavigationMenuButton } from "components/side-navigation/side-navigation-menu-button";
import { getNameOfCurrentLang } from "helpers";
import { useTranslation } from "react-i18next";
import { SideNavigationHeader } from "./side-navigation-header";

interface ISideNavigation {
    onCloseSideNav: () => void;
    isSideNavOpen: boolean;
}

const SideNavigation = ({ onCloseSideNav, isSideNavOpen }: ISideNavigation) => {
    const { t } = useTranslation();
    const {
        isOpen: languageModalIsOpen,
        onOpen: openLanguageModal,
        onClose: closeLanguageModal,
    } = useDisclosure({
        onOpen: onCloseSideNav,
    });
    const { isOpen: privacyPolicyModalIsOpen, onToggle: togglePrivacyPolicyModal } = useDisclosure({
        onOpen: onCloseSideNav,
    });

    return (
        <>
            <LanguageModal onClose={closeLanguageModal} isOpen={languageModalIsOpen} />
            <PrivacyPolicyModal
                isOpen={privacyPolicyModalIsOpen}
                onClose={togglePrivacyPolicyModal}
            />

            <Drawer
                isOpen={isSideNavOpen}
                onClose={onCloseSideNav}
                placement="left"
                size="xs"
                bg={ThemeColorVariable.Background}
            >
                <SideNavigationHeader onClose={onCloseSideNav} />
                <Box
                    as="aside"
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    flex="1"
                    h="100%"
                    color={ThemeColorVariable.OnBackground}
                >
                    <List>
                        <SideNavigationMenuButton
                            onClick={openLanguageModal}
                            areaLabel={t("miscChangeLanguage")}
                            icon={Icons.Language}
                            text={t("miscXLanguage", { 0: getNameOfCurrentLang() })}
                        />
                        <SideNavigationMenuButton
                            onClick={togglePrivacyPolicyModal}
                            icon={Icons.File}
                            text={t("privacyPolicy")}
                        />
                        <Box p={ThemeSpaceVariable.Medium}>
                            <Divider />
                        </Box>
                        <Link
                            display="flex"
                            gap={ThemeSpaceVariable.Small}
                            px={ThemeSpaceVariable.Medium}
                            href="https://www.caspeco.se/bokningssystem"
                        >
                            <PoweredByCaspeco />
                        </Link>
                    </List>
                </Box>
            </Drawer>
        </>
    );
};

export default SideNavigation;
