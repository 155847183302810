import { ExternalBookingSettings, PaymentMethod } from "types";
import { State } from "store";

export const getDefaultPaymentMethod = (
    settings: ExternalBookingSettings,
    isPrepayment: boolean,
    state: State
) => {
    if (!isPrepayment) return null;

    if (state?.payment && state.payment.payMethod !== null) {
        return state.payment.payMethod;
    }

    if (settings.paymentMethods) {
        const mobile = settings.paymentMethods.find((method) => method === PaymentMethod.Mobile);
        if (mobile) {
            return mobile;
        }
    }

    return PaymentMethod.CreditCard;
};
