import {
    ThemeBorderVariable,
    ThemeColorVariable,
    ThemeFontSizeVariable,
    ThemeRadiusVariable,
    ThemeSpaceVariable,
} from "@caspeco/casper-ui-library.base-ui.theme";
import { Box } from "@caspeco/casper-ui-library.components.box";
import { Icon, Icons } from "@caspeco/casper-ui-library.components.icon";
import { Text } from "@caspeco/casper-ui-library.components.text";

interface IEditChoiceButton {
    icon: Icons;
    text: string;
    onClick: () => void;
}

const EditSelectionButton: React.FC<IEditChoiceButton> = ({ icon, text, onClick }) => {
    return (
        <Box
            onClick={() => onClick()}
            userSelect="none"
            borderRadius={ThemeRadiusVariable.Small}
            border={ThemeBorderVariable.XSmall}
            borderColor={ThemeColorVariable.OnSurfaceBorder}
            height="48px"
            alignItems="center"
            gap={ThemeSpaceVariable.Small}
            paddingLeft={ThemeSpaceVariable.Medium}
            background={ThemeColorVariable.Surface}
            cursor="pointer"
            display="flex"
        >
            <Icon icon={icon} fontSize={ThemeFontSizeVariable.XLarge} />
            <Text flex="1" as="span" fontWeight="500" textAlign="left">
                {text}
            </Text>

            <Box pr={ThemeSpaceVariable.Medium}>
                <Icon icon={Icons.Edit} fontSize={ThemeFontSizeVariable.XLarge} />
            </Box>
        </Box>
    );
};

export default EditSelectionButton;
