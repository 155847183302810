import { ThemeSpaceVariable } from "@caspeco/casper-ui-library.base-ui.theme";
import { ToggleButton } from "@caspeco/casper-ui-library.components.toggle-button";
import { Tooltip } from "@caspeco/casper-ui-library.components.tooltip";
import { ScaleFade } from "@chakra-ui/react";
import { useAvailableTimes } from "hooks";
import { useTranslation } from "react-i18next";
import { useBookingActionsContext, useBookingStateContext } from "store";
import { TimeSlot as TimeSlotType, WebRule } from "types";
import { saveSectionNameAndWebRuleTitle } from "utils";

interface TimeSlotProps {
    time: TimeSlotType;
    timeSet: WebRule;
}

export default function TimeSlot(props: TimeSlotProps) {
    const { t } = useTranslation();

    const state = useBookingStateContext();

    const { date, guests, times } = state;

    const { handleReservation } = useBookingActionsContext();
    const units = useAvailableTimes(date).data;

    const handleClick = () => {
        const newTime = { ruleId: time.ruleId, start: time.start, end: time.end };

        saveSectionNameAndWebRuleTitle(time.name, time.title);

        const times = state.times ? [...state.times, newTime] : [newTime];

        const stateWithTimes = {
            ...state,
            times,
        };

        handleReservation(stateWithTimes);
    };

    const allTimeSets: { [key: number]: WebRule } = {};
    units.forEach((unit) => {
        unit.sections.forEach((section) => {
            section.timeSets.forEach((timeSet) => {
                allTimeSets[timeSet.id] = timeSet;
            });
        });
    });

    units.flatMap((unit) =>
        unit.sections.flatMap((section) => section.timeSets.map((timeSet) => [timeSet.id, timeSet]))
    );

    const isOtherRuleTimeOverlapping = () => {
        const { time, timeSet } = props;
        return !!times?.find((x) => {
            if (x.ruleId === time.ruleId) return false; // Skip times in same rules
            // const xTimeSet = allTimeSets[x.rule];

            return x.start.isBefore(time.end.clone().subtract(timeSet.recoupTime, "minutes"));
            //      && time.start.isBefore(x.end.clone().subtract(xTimeSet.recoupTime, "minutes"))
        });
    };

    const { time } = props;
    const isSelected = !!times?.find(
        (t) => time.ruleId === t.ruleId && time.start.isSame(t.start, "day")
    );

    const disabled =
        !(time.availableSeats.includes(guests) || time.waitListSeats.includes(guests)) ||
        isOtherRuleTimeOverlapping();

    const relevantUnavailability =
        time.unavailability &&
        time.unavailability.find((x) => {
            return x?.from <= guests && x?.to >= guests;
        });
    const tooltip = relevantUnavailability
        ? t(`unavailabilityReasonEnum.${relevantUnavailability.reason}`)
        : t("noBookableResourcesForGroupSize");

    return (
        <Tooltip key={time.start.format("HH:mm")} title={time.disabled ? tooltip : ""}>
            <ScaleFade initialScale={0.9} in={true} transition={{ enter: { duration: 0.5 } }}>
                <ToggleButton
                    key={time.start.format("HH:mm")}
                    className="timeButton"
                    isDisabled={disabled}
                    onClick={handleClick}
                    isPressed={isSelected}
                    w="full"
                    px={ThemeSpaceVariable.Small}
                    size="lg"
                >
                    {time.start.format("HH:mm")}
                </ToggleButton>
            </ScaleFade>
        </Tooltip>
    );
}
