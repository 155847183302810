import { ThemeColorVariable, ThemeSpaceVariable } from "@caspeco/casper-ui-library.base-ui.theme";
import { Button } from "@caspeco/casper-ui-library.components.button";
import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalFooter,
    ModalHeader,
} from "@caspeco/casper-ui-library.components.modal";
import { Select } from "@caspeco/casper-ui-library.components.select";
import i18n from "config/i18n";
import { trackEvent } from "logging/insights";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { getNameOfCurrentLang } from "helpers";

export default function LanguageModal({
    isOpen,
    onClose,
}: {
    isOpen: boolean;
    onClose: () => void;
}) {
    const [value, setValue] = useState(i18n.language);
    const { t } = useTranslation();
    const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setValue(e.target.value);
    };

    const onSave = () => {
        const from = i18n.language;
        i18n.changeLanguage(value).then(() => {
            // Only track if the language was actually changed
            if (from !== value) {
                trackEvent("LanguageChanged", { from, to: value });
            }

            // If the from language is the same as the to language, we may have bug
            if (from === value) {
                trackEvent("SameLanguageChange", { language: value });
            }
        });
        onClose();
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalHeader
                pt={ThemeSpaceVariable.Medium}
                px={ThemeSpaceVariable.Medium}
                display="flex"
                justifyContent="space-between"
                minH="auto"
                color={ThemeColorVariable.OnSurfaceHeader}
            >
                {t("miscXLanguage", { 0: getNameOfCurrentLang() })}
                <ModalCloseButton />
            </ModalHeader>
            <ModalBody p={ThemeSpaceVariable.Medium}>
                <Select
                    id="languageSelect"
                    w="100%"
                    size="lg"
                    name="language"
                    onChange={handleSelect}
                    value={value}
                >
                    <option value="en">English</option>
                    <option value="sv">Svenska</option>
                    <option value="nb">Norsk</option>
                    <option value="da">Dansk</option>
                    <option value="fi">Suomi</option>
                    <option value="es">Español</option>
                </Select>
            </ModalBody>
            <ModalFooter
                pt={ThemeSpaceVariable.Medium}
                px={ThemeSpaceVariable.Medium}
                pb="calc(1em + env(safe-area-inset-bottom))"
                display="flex"
                gap={ThemeSpaceVariable.Medium}
                justifyContent="flex-end"
            >
                <Button variant="primary" onClick={onSave} size={{ base: "lg", md: "md" }}>
                    {t("actionSave")}
                </Button>
            </ModalFooter>
        </Modal>
    );
}
