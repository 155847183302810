import {
    ThemeColorVariable,
    ThemeFontSizeVariable,
    ThemeFontVariable,
    ThemeSpaceVariable,
} from "@caspeco/casper-ui-library.base-ui.theme";
import { Box } from "@caspeco/casper-ui-library.components.box";
import { Heading } from "@caspeco/casper-ui-library.components.heading";
import { Text } from "@caspeco/casper-ui-library.components.text";
import { SafeHTML } from "components/safe-HTML";

interface TextRowProps {
    title: string;
    comment?: string;
}
const TextRow = ({ title, comment }: TextRowProps) => {
    return (
        <Box mb={ThemeSpaceVariable.Medium} px={ThemeSpaceVariable.Medium}>
            <Heading
                as="h6"
                color={ThemeColorVariable.OnBackgroundHeader}
                fontSize={ThemeFontSizeVariable.Large}
                fontWeight="500"
                mb={ThemeSpaceVariable.XSmall}
            >
                <SafeHTML
                    element="span"
                    options={{
                        allowedTags: ["a", "br"],
                        allowedSchemes: ["tel", "mailto", "https"],
                    }}
                    html={title}
                />
            </Heading>
            {comment && (
                <Text
                    fontFamily={ThemeFontVariable.SubHeader}
                    fontSize={ThemeFontSizeVariable.Medium}
                    color={ThemeColorVariable.OnBackgroundSubdued}
                >
                    <SafeHTML
                        element="span"
                        options={{
                            allowedTags: ["a", "br"],
                            allowedSchemes: ["tel", "mailto", "https"],
                        }}
                        html={comment}
                    />
                </Text>
            )}
        </Box>
    );
};

export default TextRow;
